import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'
import {
  api,
} from '../../../utils/index'
import {checkSeoRule, isValidRegex} from '../../../utils/rules'
import DetailSeoDependencyRow from './DetailSeoDependencyRow'
import { getRuleFromValue } from '../../../utils/rules'
import { DetailSeoRule } from './DetailSeoRule'
import { LanguageRow } from './DetailSeoLanguage/LanguageRow'
import { faTimes } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const containsOperator = 'REGEX CONTAINS (IGNORE CASE)'
const notContainsOperator = 'REGEX NOT CONTAINS (IGNORE CASE)'

const formattedContiene = {
  field: 'Keyword',
  operator: containsOperator,
  operand: '',
  next: 'AND',
}

const formattedNonContiene = {
  field: 'Keyword',
  operator: notContainsOperator,
  operand: '',
}



// Componente enorme per gestire la riga di una regola in una custom dim
// Gestisce tutte le possibili modifiche a una regola, e la differenza che esiste tra il formato mostrato a frontend (semplificato)
// E quello a backend (più completo, con tanti operatori selezionabili e pensato per gli ADV)
export default function DetailSeoRow({
    dimension,
    dimensionType,
    dimensionReport,
    linkedDimension,
    rules,
    rule,
    ruleIndex,
    removeRule,
    otherDimensions,
    style,
    disabledRules,
    setDisabledRules,
    thanosRules,
    setThanosRules,
    checked,
    setChecked,
    showLanguages,
    selectedLanguages,
    ratio,
  }) {
  // Proprietà della regola che si aggiornano quando la lista di regole cambia (si restringe, si allarga)
  const [localRule, setLocalRule] = useState(rule)
  const [ruleId, setRuleId] = useState(rules[ruleIndex].id)
  const [rulePriority, setRulePriority] = useState(rules[ruleIndex].priority)
  const [priority, setPriority] = useState(rules[ruleIndex].priority)
  const [nome, setNome] = useState(rules[ruleIndex].rule.value)
  const [waitingCreation, setWaitingCreation] = useState(false)

  // Variabili di stato contiene/non contiene, con annessi "traduttori" (convertitori di regola come arriva da db e regola formattata per i SEO)
  const resolveContiene = () => {
    // Traduce regole da db in formato per l'utente contiene/non contiene
    if (
      rules[ruleIndex].rule.conditions.length > 0 &&
      rules[ruleIndex].rule.conditions[0].operator === containsOperator
    )
      return rules[ruleIndex].rule.conditions[0].operand
    rules[ruleIndex].rule.conditions[0] = JSON.parse(
      JSON.stringify(formattedContiene)
    )
  }
  const [contiene, setContiene] = useState(resolveContiene())
  const [contieneError, setContieneError] = useState(false)

  const resolveNonContiene = () => {
    // Traduce regole da db in formato per l'utente contiene/non contiene
    if (rules[ruleIndex].rule.conditions.length >= 2)
      rules[ruleIndex].rule.conditions.slice(0, 2)
    if (
      rules[ruleIndex].rule.conditions.length > 1 &&
      rules[ruleIndex].rule.conditions[1].operator === notContainsOperator
    )
      return rules[ruleIndex].rule.conditions[1].operand
    rules[ruleIndex].rule.conditions[1] = JSON.parse(
      JSON.stringify(formattedNonContiene)
    )
  }
  const [nonContiene, setNonContiene] = useState(resolveNonContiene())
  const [nonContieneError, setNonContieneError] = useState(false)
  const [invalidTag, setInvalidTag] = useState(false)

  // Controllo della validità di una regex
  const [isInvalid, setIsInvalid] = useState(!checkSeoRule(rules[ruleIndex], contieneError, nonContieneError))

  // Sincronizzazione della regola (reset di tutti gli stati)
  const syncRule = () => {
    setIsInvalid(!checkSeoRule(rules[ruleIndex], contieneError, nonContieneError))
    setRuleId(ruleId)
    // setRuleThanos(rule.thanos)
    setRulePriority(rule.priority)
    setNome(rules[ruleIndex].rule.value)
    setPriority(rules[ruleIndex].priority)
    setContiene(resolveContiene())
    setNonContiene(resolveNonContiene())
  }

  // crea la regola a db quando non esisteva prima
  const createRule = (rule) => {
    if (!checkSeoRule(rule, contieneError, nonContieneError)) return
    setWaitingCreation(true)

    // Aggiungo il content type alla regola per la creazione
    if (dimensionType === 'custom-dimensions') {
      // Per le dimensioni custom, posso dover salvare sulla dimensione o sulla linkata a seconda della regola
      rule.object_id = rule.report_id === 'fakeid' ? dimension : linkedDimension
      rule.object_type =
        rule.report_id === 'fakeid' ? 'custom-dimensions' : 'saved-dimensions'
    } else {
      // Per le dimensioni salvata posso salvare solo sulla dimensione stessa
      rule.object_id = dimension
      rule.object_type = 'saved-dimensions'
    }

    api.post(`${prefix}custom-rules/`, rule)
      .then((res) => {
        let data = res.data;
        rules[ruleIndex].id = data
        rule.id = data
        setRuleId(data)
        setLocalRule(rule)
        setInvalidTag(false)
        notify(`Creata regola con tag "${rule.rule.value}"`, 'success')
      }, (err) => {
        let res = err.response
        if (res.status === 409){
          setInvalidTag(true)
          notify('Esiste già una regola con questo Tag', 'error')
        }else if (res.status > 409){
          notify('Errore nella comunicazione con il server', 'error')
        }
      }).finally(() => setWaitingCreation(false))

  }

  // Salva la regola a db quando è stato solo modificata
  const saveRule = (rule) => {
    if (!checkSeoRule(rule, contieneError, nonContieneError)) return

    api.put(`${prefix}custom-rules/${ruleId}/`, rule)
      .then((res) => {
        setInvalidTag(false)
        setContieneError(false)
        setNonContieneError(false)
        notify('Modifiche salvate :)', 'info')
      }, (err) => {
        let res = err.response
        if (res.status === 400){
          setInvalidTag(true)
          setContieneError(true)
          setNonContieneError(true)
          notify('Errori nelle modifiche, controlla se le regex sono corrette', 'error')
        } else if (res.status === 404){
          notify('La regola che stai modificando non sembra esistere più :(', 'warning')
        } else if (res.status === 409){
          setInvalidTag(true)
          notify('Esiste già una regola con questo Tag', 'error')
        }else if (res.status > 409){
          notify('Errore nella comunicazione con il server', 'error')
        }
      })
  }

  // cancella la regola a db
  const deleteRule = (rule) => {
    // Se la regola è appena stata creata basta rimuoverla dalla lista, senza rimuoverla a backend
    if (ruleId === -1) {
      removeRule(ruleIndex, { rules: rules })
      return
    }

    // Altrimenti rimuovo a backend, e poi rimuovo dalla lista (perchè tutto rimanga sincronizzato)
    api.delete(`${prefix}custom-rules/${ruleId}/`)
      .then(() => {
        removeRule(ruleIndex, { rules: rules })
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
  }

  // Aggiornamento della regola, che viene salvata o creata (il salvataggio/creazione è usato da altre funzioni)
  const updateRule = () => {
    // formato standard a tutte le regole SEO
    const newRule = {
      id: rules[ruleIndex].id,
      report_id: rules[ruleIndex].report_id,
      priority: priority,
      index: rules[ruleIndex].index,
      type: 'manual',
      thanos: rules[ruleIndex].thanos,
      rule: {
        value: nome,
        conditions: [
          {
            field: 'Keyword',
            operator: containsOperator,
            operand: contiene,
            next: 'AND',
          },
          {
            field: 'Keyword',
            operator: notContainsOperator,
            // ~ lo uso come placeholder, per rendere la regola sempre vera in caso questa non sia settata
            operand: nonContiene ? nonContiene : '~',
          },
        ],
      },
    }

    if (rules[ruleIndex]?.rule?.Inglese) {
      newRule.rule.Inglese = rules[ruleIndex].rule.Inglese
    }

    rules[ruleIndex] = newRule
    // Se ho un id aggiorno, altrimenti creo
    newRule.id !== -1 ? saveRule(newRule) : createRule(newRule)
    setIsInvalid(!checkSeoRule(newRule, contieneError, nonContieneError))
  }

  // Handlers dei campi
  const handlePriority = (event) => {
    setPriority(Number(event.target.value))
  }

  const handleNome = (event) => {
    // Tags should not have "," inside
    if (event.target.value.includes(',')){
      setInvalidTag(true);
    } else if(nome.includes(',') && !event.target.value.includes(',')){
      setInvalidTag(false);
    }
    setNome(event.target.value)
  }

  const handleContiene = (event) => {
    setContiene(event.target.value)
    if (event.target.value.includes('||')) setContieneError(true)
    else if (isValidRegex(event.target.value)) setContieneError(false)
    else setContieneError(true)
  }

  const handleNonContiene = (event) => {
    setNonContiene(event.target.value)
    if (event.target.value.includes('||')) setNonContieneError(true)
    else if (isValidRegex(event.target.value)) setNonContieneError(false)
    else setNonContieneError(true)
  }

  // Handler dimozione regola
  const handleRemove = () => {
    if (ruleId === -1) deleteRule(rules[ruleIndex])
    else {
      const answer = window.confirm(
        'Sicuro? La regola verrà cancellata definitivamente'
      )
      answer &&
        deleteRule(
          rules[ruleIndex]
        ) /*Passo obj per mantenere compatibilità con altra interfaccia*/
    }
  }

  // Check/uncheck regola
  const handleCheckBox = (e) => {
    const checkedCopy = JSON.parse(JSON.stringify(checked))

    if (e.target.checked)
      checkedCopy.includes(ruleId) || checkedCopy.push(ruleId)
    else
      checkedCopy.includes(ruleId) &&
        checkedCopy.splice(checkedCopy.indexOf(ruleId), 1)

    setChecked(checkedCopy)
  }

  const filterType = (r) => {
    /*
      Date le regole di una dimensione, quelle da filtrare variano in base al tipo della dimensione corrente
      -> Se sto guardando una saved dimension, voglio gestire solo le dipendenze fra saved dimension (quindi tutte quelle regole
        che non hanno un report_id)
      -> Se sto guardando una custom dimension, voglio invece gestire sia custom dimension che saved dimensions, quindi prendo tutto
    */
    if (dimensionType === 'saved-dimensions') {
      return !r.report_id
    }
    return true
  }

  const filterOptions = (rules) => {
    /*
      Date le regole di una dimensione, va a recuperarne le opzioni
      Si considerano opzioni qualsiasi regola manuale della dimensione per le custom, o solo le regole senza report id per le saved
    */
    return rules.filter((r) => r.type === 'manual' && filterType(r))
  }

  const filterValue = (rules) => {
    /*
      Date le regole di una dimensione, va a recuperare il valore
      Si considerano come valore il valore della regola dipendente che ha come operando il valore della regola attuale
      Es:
        -> Regola infusore della dimensione corrente "Accessorio"
        -> Sto filtrando le regole della dimensione "Caratteristica"
        -> La regola che dice "Se in Accessorio c'è Infusore allora metti X", è quella interessata, e X è il mio valore
    */
    return rules.filter(
      (r) =>
        r.type === 'dependency' &&
        r.rule.conditions.filter(
          (x) => x.operand === getRuleFromValue(rule.rule.value) || x.operand === getRuleFromValue(rule.rule.Inglese)
        ).length > 0 &&
        filterType(r)
    )
  }

  const getGroup = (d) => {
    // Funzione per ottenere un gruppo data una dimensione e una regola
    if (dimensionType === 'saved-dimensions') return d.group // in questo caso sono in una dimensione salvata e quindi sono nella dimensione stessa
    if (!d.linked) return null
    if (!rule.report_id) return d.linked.group // in questo caso sono su una riga di una regola salvata, quindi il gruppo è sulla dimensione linkata
  }

  // Sincronizzo la regola quando id o priority cambiano (quindi quando la lista cambia)
  useEffect(() => {
    ;(ruleId !== rule.id || rulePriority !== rule.priority) && syncRule()
  }, [rule.id, rule.priority])

  // Valuto se inserire bottone del salvataggio o meno, secondo le due condizioni (sono in una custom dimension linkata)
  const saver = false //dimensionType === "custom-dimensions" && linkedDimension; oscurato per deploy

  // Gestione delle regole disabilitate
  const isDisabled = disabledRules.includes(ruleId)
  const isThanos = thanosRules.includes(ruleId)
  const ruleVariant = () => {
    if (isDisabled) return 'secondary' // grigio
    if (isThanos) return 'dark' // giallo
    if (rule.report_id) return 'danger' // rosso
    return 'success' // verde
  }

  const disableRule = (e) => {
    e.preventDefault()
    if (dimensionType !== 'custom-dimensions' || ruleId === -1) return // La funzionalità è disponibile solo per le custom, che hanno un id (quindi escludo quelle non ancora salvate)

    api.post(`${prefix}disabled-rules/`, { rule: ruleId, custom: dimension })
      .then(loginRedirect)
      .then((data) => {
        console.log(data)
        if(isThanos) {
          const thanosRulesCopy = JSON.parse(JSON.stringify(thanosRules))
          thanosRulesCopy.splice(thanosRules.indexOf(ruleId), 1)
          setThanosRules(thanosRulesCopy)
        }

        const disabledRulesCopy = JSON.parse(JSON.stringify(disabledRules))
        disabledRulesCopy.push(ruleId)
        setDisabledRules(disabledRulesCopy)
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
  }

  const enableRule = (e) => {
    e.preventDefault()

    if (dimensionType !== 'custom-dimensions' || ruleId === -1) return // La funzionalità è disponibile solo per le custom, che hanno un id (quindi escludo quelle non ancora salvate)

    api.delete(`${prefix}disabled-rules/`, {
      data: {rule: ruleId, custom: dimension},
    })
      .then(loginRedirect)
      .then((data) => {
        const disabledRulesCopy = JSON.parse(JSON.stringify(disabledRules))
        disabledRulesCopy.splice(disabledRules.indexOf(ruleId), 1)
        setDisabledRules(disabledRulesCopy)
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
  }

    const disableThanos = (e) => {
      e.preventDefault()

      if (dimensionType !== 'custom-dimensions' || ruleId === -1) return // La funzionalità è disponibile solo per le custom, che hanno un id (quindi escludo quelle non ancora salvate)
        api.delete(`${prefix}thanos-rules/`, {
          data: {rule: ruleId, custom: dimension},
        })
          .then(loginRedirect)
          .then((data) => {
            console.log(data)
            const thanosRulesCopy = JSON.parse(JSON.stringify(thanosRules))
            thanosRulesCopy.splice(thanosRules.indexOf(ruleId), 1)
            setThanosRules(thanosRulesCopy)
          })
          .catch((e) => {
            notify(e.message, 'error')
          })
  }

  const enableThanos = (e) => {
    e.preventDefault()
    if (dimensionType !== 'custom-dimensions' || ruleId === -1) return // La funzionalità è disponibile solo per le custom, che hanno un id (quindi escludo quelle non ancora salvate)

    api.post(`${prefix}thanos-rules/`, { rule: ruleId, custom: dimension })
      .then(loginRedirect)
      .then((data) => {
        console.log(data)
        const thanosRulesCopy = JSON.parse(JSON.stringify(thanosRules))
        thanosRulesCopy.push(ruleId)
        setThanosRules(thanosRulesCopy)
      })
      .catch((e) => {
        notify(e.message, 'error')
      })
  }

  const status = (e) => {
    e.preventDefault()

    if (isDisabled) {
      enableRule(e)
      enableThanos(e)
    }
    else if(isThanos) {
      disableThanos(e)
    }
    else {
      disableRule(e)
    }
    notify('Modifiche salvate :)', 'info')
  }

  return (
    <div
      className="d-flex align-items-center"
      id={"Rule"}
      style={{
        ...style,
        top: `${parseFloat(style.top)}px`,
      }}
    >
      <div style={{ width: ratio.checkbox }} id={`rule-${ruleIndex}`}>
        <fieldset className="w-seo-control-field d-flex align-items-center">
          <div style={{ width: '20px' }} className="mr-2">
            {/* Tiene allineate le checkbox */}
            {ruleId !== -1 && (
              <input
                type="checkbox"
                checked={checked.includes(ruleId)}
                onClick={handleCheckBox}
              />
            )}
          </div>
          <Button
            id="ruleType"
            variant={ruleVariant()}
            style={isThanos ? {color: "violet", backgroundColor: "violet", borderColor: "violet"} : {}}
            title={isThanos ? "Stopword" : isDisabled ? "Regola disabilitata" : rule.report_id ? 'Regola custom' : 'Regola salvata'}
            onClick={status}
          />
          <button
            type="button"
            title="Rimuovi regola"
            onClick={handleRemove}
            className="w-remove-button text-danger ml-2"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {saver && (
            // Se sono in una custom mostro il save, altrimenti mostro il de-save
            <DetailSeoRule
              rule={rule}
              rules={rules}
              ruleIndex={ruleIndex}
              dim={dimension}
              type={Boolean(rule.report_id)}
              syncRule={syncRule}
            />
          )}
        </fieldset>
      </div>
      <div style={{ width: ratio.priority }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="number"
            value={priority}
            disabled={isDisabled || waitingCreation}
            className="form-control"
            placeholder="Priorità"
            onChange={handlePriority}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
      <LanguageRow
        showLanguages={showLanguages}
        selectedLanguages={selectedLanguages}
        rule={localRule}
        disabled={isInvalid || isDisabled || waitingCreation}
        ratio={ratio}
      />
      <div style={{ width: ratio.tag }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={nome}
            value={nome}
            disabled={isDisabled || waitingCreation}
            className="form-control font-weight-bold"
            placeholder="Operando"
            isInvalid={invalidTag}
            onChange={handleNome}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
      <div style={{ width: ratio.regex }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={contiene}
            value={contiene}
            disabled={isDisabled || waitingCreation}
            isInvalid={contieneError}
            className="form-control"
            placeholder="Operando"
            onChange={handleContiene}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
      <div style={{ width: ratio.regex }}>
        <fieldset className="w-seo-control-field">
          <Form.Control
            type="text"
            title={nonContiene}
            value={nonContiene}
            disabled={isDisabled || waitingCreation}
            isInvalid={nonContieneError}
            className="form-control"
            placeholder="Operando"
            onChange={handleNonContiene}
            onBlur={updateRule}
          />
        </fieldset>
      </div>
      {otherDimensions.map((d) => (
        <DetailSeoDependencyRow
          dimension={d}
          report={dimensionReport}
          name={d.name}
          group={getGroup(d)}
          disabled={isInvalid || isDisabled || waitingCreation}
          rules={rules}
          index={ruleIndex}
          options={filterOptions(d.rules)}
          valueList={filterValue(d.rules)}
          ratio={ratio}
        />
      ))}
    </div>
  )
}
